import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">development</Text>
                <Title variant="secSm" className="my-4">
                  Seattle Crime Rate{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Seattle Crime Rate is an interactive web application that informs users about crime rate data in Seattle. There
                  are several interactive graphs and a map of locations where crimes have occurred. This web application is built
                  entirely from R and leverages the Seattle Police Department Police Report Incident dataset.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Role</Text>
                <Title variant="cardBig" className="mt-3">
                  Data Scientist
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Jan 2018 - Mar 2018
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://ryandaaang.shinyapps.io/Info-201-Final-Project/" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Live Work</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: 60}}>
              Project Process
            </Title>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  01. Description
                </Title>
                <Text variant="p" className="mb-4">
                  Our target audience are users currently living in Seattle or are planning to move to Seattle. Through our web application, our  
                  users will be able to learn about increases or decreases in crime over time, the most common types of crime, and the areas
                  and locations in Seattle with the highest crime rates.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  02. Technical Requirements
                </Title>
                <Text variant="p" className="mb-4">
                  Our web application uses 
                  <a href="https://shiny.rstudio.com/" target="_blank"> Shiny </a>
                  which is an R package that helps in building interactive web applications straight from R.
                  <a href="https://www.r-project.org/" target="_blank"> R </a>
                  is a programming language built for statistical computing and graphics.
                  Our team extracted data from the Seattle Police Department Police Report Incident dataset
                  and conducted several techniques of data wrangling to create various types of visualizations. We leveraged three different R
                  packages to us build our visualizations including
                  <a href="https://plotly.com/r/" target="_blank"> Plotly</a>,
                  <a href="https://ggplot2.tidyverse.org/" target="_blank"> ggplot2</a>, and
                  <a href="https://rstudio.github.io/leaflet/" target="_blank"> Leaflet</a>. I was in charge of creating a map visualization
                  that plots each crime report on a map of Seattle along with the type of crime committed. The map uses marker clusters that automatically
                  groups large number of markers on a map together as users zoom out. The marker clusters are also color coded to help users easily identify areas
                  with larger rates of crime.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  03. Implementation
                </Title>
                <Text variant="p" className="mb-4">
                  Our team hosted our code on our
                  <a href="https://github.com/JuliusColeman/final-project" target="_blank"> GitHub repository </a> allowing for multiple team
                  members to work on the same files at the same time. This helped us manage and track changes made to our web application code.
                  To deploy our web application, I used 
                  <a href="https://www.shinyapps.io/" target="_blank"> shinyapps.io</a> that allowed for a secure and scalable deployment.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  04. Outcomes
                </Title>
                <Text variant="p" className="mb-4">
                  This project was my first experience working in a team to successfully build a live web application. It was also my first
                  experience using a version control software to collaborate on code. Coding the interactive map and deploying the web application
                  gave me the foundation for teaching myself new tools and the ability to troubleshoot various issues. Once our project was
                  completed, we presented our web application in a project fair where attendees interacted with our features. We received
                  numerous praises for how intuitive and seamless our web application functioned.
                </Text>
          </Container>
        </Section>
        <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/easy-talk">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Easy Talk
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
